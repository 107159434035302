import {
    Appwrite,
    getAppwriteFilters,
    getAppwritePagination,
    getAppwriteSorting,
    ID,
    Teams,
    Models,
} from "@pankod/refine-appwrite";
import { DataProvider } from "@pankod/refine-core";

export const teamsResources = {
    teams: "teams",
    memberships: "memberships",
    membershipStatus: "membershipStatus",
};

export const teamsProvider = (
    appwriteClient: Appwrite
): Required<DataProvider> => {
    const teams = new Teams(appwriteClient);

    return {
        getList: async ({
            metaData,
            resource,
            hasPagination = true,
            pagination = { current: 1, pageSize: 10 },
            filters,
            sort,
        }) => {
            const search = metaData?.search;
            const appwriteFilters = getAppwriteFilters(filters);
            const appwritePagination = hasPagination
                ? getAppwritePagination(pagination)
                : [];

            const appwriteSorts = getAppwriteSorting(sort);

            let total = 0;
            let data: { $id: string; [k: string]: any }[] = [];

            switch (resource) {
                case teamsResources.teams:
                    const teamList = await teams.list(
                        [
                            ...appwriteFilters,
                            ...appwritePagination,
                            ...appwriteSorts,
                        ],
                        search
                    );
                    data = teamList.teams;
                    total = teamList.total;
                    break;
                case teamsResources.memberships:
                    const teamId = metaData?.teamId;
                    const membershipList = await teams.listMemberships(
                        teamId,
                        [
                            ...appwriteFilters,
                            ...appwritePagination,
                            ...appwriteSorts,
                        ],
                        search
                    );
                    data = membershipList.memberships;
                    total = membershipList.total;
                    break;
                default:
                    throw new Error("Invalid resource");
            }

            return {
                data: data.map(({ $id, ...restData }: { $id: string }) => ({
                    id: $id,
                    ...restData,
                })) as any,
                total,
            };
        },
        getOne: async ({ metaData, resource, id }) => {
            let data: { $id: string; [k: string]: any } = { $id: "" };

            switch (resource) {
                case teamsResources.teams:
                    data = await teams.get(id.toString());

                    break;
                case teamsResources.memberships:
                    const teamId = metaData?.teamId;
                    data = (await teams.getMembership(
                        teamId,
                        id.toString()
                    )) as unknown as Models.Membership;
                    break;
                default:
                    throw new Error("Invalid resource");
            }

            const { $id, ...restData } = data;

            return {
                data: {
                    id: $id,
                    ...restData,
                },
            } as any;
        },
        update: async ({ resource, id, variables }) => {
            let data: { $id: string; [k: string]: any } = { $id: "" };

            switch (resource) {
                case teamsResources.membershipStatus:
                    const membershipId = (variables as any).membershipId;
                    const userId = (variables as any).userId;
                    const secret = (variables as any).secret;

                    data = await teams.updateMembershipStatus(
                        id.toString(),
                        membershipId,
                        userId,
                        secret
                    );
                    break;

                default:
                    throw new Error("Invalid resource");
            }

            const { $id, ...restData } = data;

            return {
                data: {
                    id: $id,
                    ...restData,
                },
            } as any;
        },
        create: async ({ metaData, resource, variables }) => {
            let data: { $id: string; [k: string]: any } = { $id: "" };

            let teamId = metaData?.teamId;
            const roles = metaData?.roles;
            const { email, name } = variables as any;

            switch (resource) {
                case teamsResources.teams:
                    data = await teams.create(
                        teamId ?? ID.unique(),
                        name,
                        roles
                    );
                    break;

                case teamsResources.memberships:
                    const url = metaData?.url;
                    data = await teams.createMembership(
                        teamId,
                        email,
                        roles ?? [],
                        url,
                        name
                    );
                    break;
                default:
                    throw new Error("Invalid resource");
            }

            const { $id, ...restData } = data;

            return {
                data: {
                    id: $id,
                    ...restData,
                },
            } as any;
        },
        createMany: async ({ metaData, resource, variables }) => {
            throw Error("Not implemented.");
        },
        deleteOne: async ({ metaData, resource, id }) => {
            throw Error("Not implemented.");
        },
        deleteMany: async ({ metaData, resource, ids }) => {
            throw Error("Not implemented.");
        },
        getMany: async ({ metaData, resource, ids }) => {
            throw Error("Not implemented.");
        },
        updateMany: async ({ metaData, resource, ids, variables }) => {
            throw Error("Not implemented.");
        },
        getApiUrl: () => {
            throw Error(
                "'getApiUrl' method is not implemented on refine-appwrite data provider."
            );
        },
        custom: () => {
            throw Error(
                "'custom' method is not implemented on refine-appwrite data provider."
            );
        },
    };
};
