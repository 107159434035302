import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useGetIdentity, useLogout, useNavigation } from "@pankod/refine-core";
import { Link, Stack } from "@pankod/refine-mui";
import { Constants } from "utility";
import {
    IOrganizationContext,
    OrganizationContext,
} from "context/organization";

export const Header: React.FC = () => {
    const { list, push } = useNavigation();
    const { data: user } = useGetIdentity<{ name: string; email: string }>();
    const { mutate: mutateLogout } = useLogout();

    const name = user?.name || user?.email || "";

    const { organization } = React.useContext(
        OrganizationContext
    ) as IOrganizationContext;

    const pages = [
        {
            label: "Organizations",
            path: "/organizations",
        },
    ];

    if (organization) {
        pages.push({
            label: "Team",
            path: `/organizations/${organization}/team`,
        });
        pages.push({
            label: "Lists",
            path: `/organizations/${organization}/lists`,
        });
    }

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
        null
    );
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    );

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar color="default" position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* <AdbIcon
                        sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
                    /> */}
                    <Link
                        variant="h5"
                        noWrap
                        component="button"
                        title="A Multi-Tenant SaaS Starter on Appwrite"
                        onClick={() =>
                            list(Constants.collections.organizations)
                        }
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            fontWeight: 800,
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        Mantis
                    </Link>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        {pages.length > 0 && (
                            <>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>

                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: "block", md: "none" },
                                    }}
                                >
                                    {pages.map((page) => (
                                        <MenuItem
                                            key={page.path}
                                            onClick={() => {
                                                push(page.path);
                                                handleCloseNavMenu();
                                            }}
                                        >
                                            <Typography textAlign="center">
                                                {page.label}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        )}
                    </Box>
                    <Link
                        variant="h4"
                        noWrap
                        component="button"
                        title="A Multi-Tenant SaaS Starter on Appwrite"
                        onClick={() =>
                            list(Constants.collections.organizations)
                        }
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            fontWeight: 800,
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        Mantis
                    </Link>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        {pages.map((page) => (
                            <Button
                                key={page.path}
                                onClick={() => {
                                    push(page.path);
                                    handleCloseNavMenu();
                                }}
                                sx={{
                                    my: 2,
                                    color: "black",
                                    display: "block",
                                }}
                            >
                                {page.label}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Stack
                            direction="row"
                            gap="16px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="subtitle2">{name}</Typography>
                            <Tooltip title="Open settings">
                                <IconButton
                                    onClick={handleOpenUserMenu}
                                    sx={{ p: 0 }}
                                >
                                    <Avatar alt={name}>
                                        {name
                                            .split(" ")
                                            .map((w) => w.substring(0, 1))
                                            .join("")}
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: "45px" }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem
                                    key="Logout"
                                    onClick={() => {
                                        mutateLogout();
                                        // handleCloseUserMenu();
                                    }}
                                >
                                    <Typography textAlign="center">
                                        Logout
                                    </Typography>
                                </MenuItem>
                            </Menu>
                        </Stack>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
