import { AppwriteException, ID } from "@pankod/refine-appwrite";
import { AuthProvider } from "@pankod/refine-core";
import { account } from "./appwriteClient";

const authProvider: AuthProvider = {
    register: async ({ name, email, password, sendEmailConfirmation }) => {
        await account.create(ID.unique(), email, password, name);
        await account.createEmailSession(email, password);
        if (sendEmailConfirmation) {
            account.createVerification(
                `${window.location.origin}/verify-email`
            );
        }
    },
    login: async ({ email, password }) => {
        try {
            await account.createEmailSession(email, password);
            return Promise.resolve();
        } catch (e) {
            const { type, message, code } = e as AppwriteException;
            return Promise.reject({
                message,
                name: `${code} - ${type}`,
            });
        }
    },
    logout: async () => {
        await account.deleteSession("current");

        return "/";
    },
    checkError: (error) => {
        if (error.code === "401") {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: async () => {
        await account.get();
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
        const user = await account.get();

        return user;
    },
};

export { authProvider };
