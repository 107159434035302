import { TextField } from "@pankod/refine-mui";
import { UseModalFormReturnType } from "@pankod/refine-react-hook-form";

import { Modal } from "../modal";

export const Invite: React.FC<UseModalFormReturnType> = ({
    register,
    formState: { errors },
    refineCore: { onFinish, formLoading },
    handleSubmit,
    modal: { visible, close },
    saveButtonProps,
}) => {
    return (
        <Modal
            isOpen={visible}
            onClose={close}
            title="Invite a user"
            submitText={formLoading ? "Loading" : "Create"}
            onSubmitClick={saveButtonProps.onClick}
            submitDisabled={saveButtonProps.disabled}
        >
            <form className="form" onSubmit={handleSubmit(onFinish)}>
                <TextField
                    label="Email"
                    placeholder="jane@example.com"
                    margin="normal"
                    fullWidth
                    {...register("email", {
                        required: "This field is required",
                    })}
                />
                <TextField
                    label="Name"
                    placeholder="Jane"
                    margin="normal"
                    fullWidth
                    {...register("name", {
                        required: "This field is required",
                    })}
                />
            </form>
        </Modal>
    );
};
