import React from "react";
import {
    useDataGrid,
    DataGrid,
    GridColumns,
    List,
    BooleanField,
    Typography,
    DeleteButton,
} from "@pankod/refine-mui";
import { useModalForm } from "@pankod/refine-react-hook-form";

import { Create } from "components/tasks";
import { IList, IOrganizationDetail, ITask } from "interfaces";
import { Constants } from "utility";
import { useOne, useRouterContext, useUpdate } from "@pankod/refine-core";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import { Permission, Role } from "@pankod/refine-appwrite";

export const TaskList: React.FC = () => {
    const { organizationId, listId } = useRouterContext().useParams<{
        organizationId: string;
        listId: string;
    }>();
    const { mutate } = useUpdate<ITask>();

    // Setup create modal
    const createModalFormReturnValues = useModalForm<ITask>({
        defaultValues: {
            listId: listId,
        },
        refineCoreProps: {
            action: "create",
            redirect: false,
            resource: Constants.collections.tasks,
            metaData: {
                databaseId: organizationId,
                permissions: [
                    Permission.read(Role.team(organizationId)),
                    Permission.write(Role.team(organizationId)),
                ],
            },
        },
    });

    const {
        modal: { show: showCreateModal },
    } = createModalFormReturnValues;

    // Fetch Organiation Detail
    const { data: organizationDetailsData } = useOne<IOrganizationDetail>({
        metaData: { databaseId: Constants.databases.general },
        resource: Constants.collections.organizationDetails,
        id: organizationId,
        queryOptions: {
            enabled: !!organizationId,
        },
    });

    const organizationName = organizationDetailsData?.data.name ?? "";

    // Fetch List
    const { data: listData } = useOne<IList>({
        metaData: { databaseId: organizationId },
        resource: Constants.collections.lists,
        id: listId,
        queryOptions: {
            enabled: !!listId,
        },
    });

    // Fetch Tasks
    const { dataGridProps } = useDataGrid<ITask>({
        metaData: { databaseId: organizationId },
        resource: Constants.collections.tasks,
        permanentFilter: [{ field: "listId", operator: "eq", value: listId }],
        queryOptions: {
            enabled: !!organizationId && !!listId,
        },
    });

    // Setup table columns
    const columns: GridColumns<ITask> = [
        {
            field: "isComplete",
            headerName: "Complete",
            align: "center",
            renderCell: function render(params) {
                return (
                    <BooleanField
                        value={params.row.isComplete}
                        trueIcon={<CheckBoxOutlined />}
                        falseIcon={<CheckBoxOutlineBlank />}
                        valueLabelTrue="complete"
                        valueLabelFalse="incomplete"
                        onClick={(e) => {
                            mutate({
                                resource: Constants.collections.tasks,
                                id: params.row.id,
                                metaData: { databaseId: organizationId },
                                values: { isComplete: !params.row.isComplete },
                            });
                        }}
                    />
                );
            },
        },
        {
            field: "content",
            headerName: "Content",
            minWidth: 250,
            flex: 1,
            renderCell: function render(params) {
                return (
                    <Typography
                        sx={{
                            textDecoration: params.row.isComplete
                                ? "line-through"
                                : "none",
                        }}
                    >
                        {params.row.content}
                    </Typography>
                );
            },
        },
        {
            headerName: "Actions",
            field: "actions",
            minWidth: 250,
            renderCell: function render(params) {
                return (
                    <DeleteButton
                        hideText
                        recordItemId={params.row.id}
                        resourceNameOrRouteName={Constants.collections.tasks}
                        metaData={{ databaseId: organizationId }}
                    />
                );
            },
        },
    ];

    return (
        <>
            <Create {...createModalFormReturnValues} />
            <List
                title={`${organizationName} List ${listData?.data.name} Tasks`}
                canCreate={true}
                createButtonProps={{ onClick: () => showCreateModal() }}
            >
                <DataGrid {...dataGridProps} columns={columns} autoHeight />
            </List>
        </>
    );
};
