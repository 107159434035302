import React, { useContext } from "react";
import {
    useDataGrid,
    DataGrid,
    GridColumns,
    TagField,
    List,
    GridEventListener,
} from "@pankod/refine-mui";
import {
    useGetIdentity,
    useInvalidate,
    useMany,
    useNavigation,
    useTranslate,
} from "@pankod/refine-core";
import { IOrganization, IOrganizationDetail } from "interfaces";
import { Constants } from "utility";
import {
    OrganizationContext,
    IOrganizationContext,
} from "context/organization";
import { useModalForm } from "@pankod/refine-react-hook-form";
import { Create } from "components/organizations/create";
import { EmailVerificationAlert } from "components/email-verification-alert";

export const OrganizationList: React.FC = () => {
    const { list } = useNavigation();
    const { setOrganization } = useContext(
        OrganizationContext
    ) as IOrganizationContext;
    const translate = useTranslate();
    const invalidate = useInvalidate();

    const { isLoading: identityIsLoading, data: identity } = useGetIdentity();
    const isVerified = !identityIsLoading && identity.emailVerification;

    // Setup create modal
    const resource = "create-organization";
    const createModalFormReturnValues = useModalForm({
        refineCoreProps: {
            dataProviderName: "functions",
            action: "create",
            redirect: false,
            resource: resource,
            successNotification: {
                key: `create-${resource}-notification`,
                message: `Successfully created organization`,
                description: translate("notifications.success", "Success"),
                type: "success",
            },
            onMutationSuccess: () => {
                const resources = [
                    Constants.collections.organizationDetails,
                    Constants.collections.organizations,
                ];
                resources.forEach((resource) => {
                    invalidate({
                        resource: resource,
                        invalidates: ["list", "many"],
                    });
                });
            },
        },
    });

    const {
        modal: { show: showCreateModal },
    } = createModalFormReturnValues;

    // Fetch Organizations
    const { dataGridProps } = useDataGrid<IOrganizationDetail>({
        metaData: { databaseId: Constants.databases.general },
    });

    // Fetch Organization Details
    const ids = dataGridProps.rows.map((item) => item.id);
    const { data: organizationsData, isLoading } = useMany<IOrganization>({
        metaData: { databaseId: Constants.databases.general },
        resource: Constants.collections.organizations,
        ids: ids,
        queryOptions: {
            enabled: ids.length > 0,
        },
    });

    // Create a map of organization details for faster lookup
    const organizationDetailsById = React.useMemo<
        Record<string, IOrganization>
    >(() => {
        const result: Record<string, IOrganization> = {};

        organizationsData?.data.forEach((d) => {
            result[d.id] = d;
        });

        return result;
    }, [organizationsData]);

    // Setup table columns
    const columns = React.useMemo<GridColumns<IOrganizationDetail>>(
        () => [
            {
                field: "name",
                headerName: "Name",
                minWidth: 250,
                flex: 1,
                renderCell: function render(params) {
                    return params.row.name;
                },
            },
            {
                field: "status",
                headerName: "Status",
                minWidth: 50,
                sortable: false,
                filterable: false,
                renderCell: function render({ row }) {
                    if (isLoading) {
                        return "Loading...";
                    }

                    return (
                        <TagField
                            value={
                                organizationDetailsById[row.id]?.status || ""
                            }
                        />
                    );
                },
            },
        ],
        [organizationDetailsById, isLoading]
    );

    const handleClick: GridEventListener<"rowClick"> = (params) => {
        const { id } = params;
        setOrganization(id.toString());
        list(`organizations/${id}/lists`);
    };

    return (
        <>
            <Create {...createModalFormReturnValues} />
            {!isVerified && <EmailVerificationAlert />}
            <List
                canCreate={isVerified}
                createButtonProps={{ onClick: () => showCreateModal() }}
            >
                <DataGrid
                    {...dataGridProps}
                    columns={columns}
                    autoHeight
                    onRowClick={handleClick}
                />
            </List>
        </>
    );
};
