import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@pankod/refine-mui";
import { PropsWithChildren } from "react";

type ModalPropsType = {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    startIcon?: React.ReactNode;
    submitText?: string;
    onSubmitClick: (e: React.BaseSyntheticEvent<object, any, any>) => void;
    submitDisabled: boolean;
};

export const Modal: React.FC<PropsWithChildren<ModalPropsType>> = ({
    isOpen,
    onClose,
    title,
    startIcon,
    submitText,
    onSubmitClick,
    submitDisabled,
    children,
}) => {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    variant="contained"
                    onClick={onSubmitClick}
                    disabled={submitDisabled}
                    startIcon={startIcon}
                >
                    {submitText ?? "Submit"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
