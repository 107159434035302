import React, { useContext, useEffect } from "react";
import {
    useDataGrid,
    DataGrid,
    GridColumns,
    List,
    GridEventListener,
} from "@pankod/refine-mui";
import { useModalForm } from "@pankod/refine-react-hook-form";

import { Create } from "components/lists";
import { IList, IOrganizationDetail } from "interfaces";
import { Constants } from "utility";
import {
    OrganizationContext,
    IOrganizationContext,
} from "context/organization";
import { useNavigation, useOne, useRouterContext } from "@pankod/refine-core";

export const ListList: React.FC = () => {
    const { list } = useNavigation();
    const { organization, setOrganization } = useContext(
        OrganizationContext
    ) as IOrganizationContext;

    const { id } = useRouterContext().useParams<{ id: string }>();

    useEffect(() => {
        if (organization !== id) {
            setOrganization(id);
        }
    });

    // Fetch Organiation Details
    const { data: organizationDetailsData } = useOne<IOrganizationDetail>({
        metaData: { databaseId: Constants.databases.general },
        resource: Constants.collections.organizationDetails,
        id: organization,
        queryOptions: {
            enabled: !!organization,
        },
    });

    const organizationName = organizationDetailsData?.data.name ?? "";

    // Fetch Lists
    const { dataGridProps } = useDataGrid<IList>({
        metaData: { databaseId: organization },
        resource: Constants.collections.lists,
        queryOptions: {
            enabled: !!organization,
        },
    });

    // Setup create modal
    const createModalFormReturnValues = useModalForm({
        refineCoreProps: {
            action: "create",
            redirect: false,
            resource: Constants.collections.lists,
            metaData: {
                databaseId: organization,
            },
        },
    });

    const {
        modal: { show: showCreateModal },
    } = createModalFormReturnValues;

    // Setup table columns
    const columns: GridColumns<IList> = [
        {
            field: "name",
            headerName: "Name",
            minWidth: 250,
            flex: 1,
            renderCell: function render(params) {
                return params.row.name;
            },
        },
    ];

    const handleClick: GridEventListener<"rowClick"> = (params) => {
        const { id } = params;
        list(`organizations/${organization}/lists/${id}/tasks`);
    };

    return (
        <>
            <Create {...createModalFormReturnValues} />
            <List
                title={`${organizationName} Lists`}
                canCreate={true}
                createButtonProps={{ onClick: () => showCreateModal() }}
            >
                <DataGrid
                    {...dataGridProps}
                    columns={columns}
                    autoHeight
                    onRowClick={handleClick}
                />
            </List>
        </>
    );
};
