import * as React from "react";
import Button from "@mui/material/Button";
import { Alert, Box } from "@pankod/refine-mui";
import { account } from "utility";
import { useNotification } from "@pankod/refine-core";

export const EmailVerificationAlert: React.FC = () => {
    const { open } = useNotification();

    const onClick = async () => {
        const verification = await account.createVerification(
            `${window.location.origin}/verify-email`
        );

        open?.({
            message: "Sucessfully sent verification email",
            description: "Successful",
            key: `${verification.$id}-verification-notification`,
            type: "success",
        });
    };

    return (
        <Box mb={2}>
            <Alert
                severity="warning"
                action={
                    <Button color="inherit" size="small" onClick={onClick}>
                        Resend Verification
                    </Button>
                }
            >
                In order to create an organization, you must verify your email!
            </Alert>
        </Box>
    );
};
