import {
    useAuthenticated,
    useNavigation,
    useNotification,
    useRouterContext,
} from "@pankod/refine-core";
import { CircularProgress, Stack } from "@pankod/refine-mui";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { Constants, account } from "utility";

export const VerifyEmail: React.FC = () => {
    const { search } = useRouterContext().useLocation();
    const { list } = useNavigation();
    const queryClient = useQueryClient();
    const { open } = useNotification();

    const { isFetching } = useAuthenticated();

    const searchParams = new URLSearchParams(search);

    const userId = searchParams.get("userId");
    const secret = searchParams.get("secret");

    useEffect(() => {
        if (isFetching) return;
        if (secret && userId) {
            account.updateVerification(userId, secret).then(
                (verification) => {
                    open?.({
                        message: "Sucessfully verified email",
                        description: "Successful",
                        key: `${verification.$id}-verification-notification`,
                        type: "success",
                    });
                    queryClient.invalidateQueries(["useAuthenticated"]);
                    queryClient.invalidateQueries(["getUserIdentity"]);
                    queryClient.invalidateQueries(["usePermissions"]);
                    list(Constants.collections.organizations);
                },
                (err) => {
                    open?.({
                        message: `Error verifying email (status code: ${err.code})`,
                        description: err.message,
                        key: "verify-email-notification",
                        type: "error",
                    });
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, secret, userId]);

    return (
        <Stack
            direction="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: "100vh" }}
        >
            <CircularProgress />
        </Stack>
    );
};
