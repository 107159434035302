import { Refine } from "@pankod/refine-core";
import {
    notificationProvider,
    ReadyPage,
    ErrorComponent,
    AuthPage,
    Layout,
    Typography,
    Alert,
    Link,
} from "@pankod/refine-mui";
import {
    Constants,
    dataProvider,
    functionExecutionsProvider,
    teamsProvider,
} from "utility";
import routerProvider from "@pankod/refine-react-router-v6";
import { appwriteClient, authProvider } from "utility";
import { OrganizationList } from "pages/organizations";
import { MembershipList } from "pages/memberships";
import { ListList } from "pages/lists";
import { Header } from "components/header";
import { TaskList } from "pages/tasks";
import { AcceptInvite } from "pages/accept-invite";
import { VerifyEmail } from "pages/verify-email";
import { RegisterPage } from "pages/register";

function App() {
    const renderContent = (content: React.ReactNode) => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Alert severity="info" sx={{ mb: 2 }}>
                    This mutli-tenant task list uses{" "}
                    <Link
                        color="inherit"
                        target="_blank"
                        rel="noopener"
                        href="https://mantis.g33kdev.com/"
                    >
                        Mantis
                    </Link>{" "}
                    to create a new Organization, separating each tenant.
                </Alert>

                {content}
            </div>
        );
    };

    return (
        <Refine
            disableTelemetry
            notificationProvider={notificationProvider}
            Title={() => (
                <Typography
                    color="#ffffff"
                    align="center"
                    variant="subtitle1"
                    component="h1"
                    fontWeight={600}
                >
                    Mantis
                </Typography>
            )}
            Sider={() => <div></div>}
            Header={Header}
            Layout={Layout}
            ReadyPage={ReadyPage}
            catchAll={<ErrorComponent />}
            authProvider={authProvider}
            LoginPage={() => (
                <AuthPage rememberMe={false} renderContent={renderContent} />
            )}
            routerProvider={{
                ...routerProvider,
                routes: [
                    {
                        path: "/register",
                        element: (
                            <RegisterPage
                                renderContent={renderContent}
                                formProps={{
                                    defaultValues: {
                                        sendEmailConfirmation: true,
                                    },
                                }}
                            />
                        ),
                    },
                    {
                        path: "/forgot-password",
                        element: <AuthPage type="forgotPassword" />,
                    },
                    {
                        path: "/update-password",
                        element: <AuthPage type="updatePassword" />,
                    },
                    {
                        path: "/accept-invite",
                        element: <AcceptInvite />,
                    },
                    {
                        path: "/verify-email",
                        element: <VerifyEmail />,
                    },
                ],
            }}
            dataProvider={{
                default: dataProvider(appwriteClient),
                teams: teamsProvider(appwriteClient),
                functions: functionExecutionsProvider(appwriteClient),
            }}
            resources={[
                {
                    name: Constants.collections.organizationDetails,
                    list: OrganizationList,
                    options: {
                        label: "Organizations",
                        route: "organizations",
                    },
                },
                {
                    name: "memberships",
                    list: MembershipList,
                    options: {
                        label: "Team",
                        route: "organizations/:id/team",
                    },
                },
                {
                    name: Constants.collections.lists,
                    list: ListList,
                    options: {
                        label: "Lists",
                        route: "organizations/:id/lists",
                    },
                },
                {
                    name: Constants.collections.tasks,
                    list: TaskList,
                    options: {
                        label: "Tasks",
                        route: "/organizations/:organizationId/lists/:listId/tasks",
                    },
                },
            ]}
        />
    );
}

export default App;
