import {
    useAuthenticated,
    useNavigation,
    useRouterContext,
    useUpdate,
} from "@pankod/refine-core";
import { CircularProgress, Stack } from "@pankod/refine-mui";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { Constants, teamsResources } from "utility";

export const AcceptInvite: React.FC = () => {
    const { search } = useRouterContext().useLocation();
    const { list } = useNavigation();
    const queryClient = useQueryClient();

    const { isFetching } = useAuthenticated();

    const searchParams = new URLSearchParams(search);

    const teamId = searchParams.get("teamId");
    const membershipId = searchParams.get("membershipId");
    const userId = searchParams.get("userId");
    const secret = searchParams.get("secret");

    const { mutate } = useUpdate();

    useEffect(() => {
        if (isFetching) return;
        if (teamId && membershipId && secret && userId) {
            mutate(
                {
                    dataProviderName: "teams",
                    resource: teamsResources.membershipStatus,
                    id: teamId,
                    values: { membershipId, secret, userId },
                },
                {
                    onSuccess: async () => {
                        queryClient.invalidateQueries(["useAuthenticated"]);
                        queryClient.invalidateQueries(["getUserIdentity"]);
                        queryClient.invalidateQueries(["usePermissions"]);
                        list(Constants.collections.organizations);
                    },
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, membershipId, mutate, queryClient, secret, teamId, userId]);

    return (
        <Stack
            direction="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: "100vh" }}
        >
            <CircularProgress />
        </Stack>
    );
};
