import {
    Appwrite,
    Databases,
    getAppwriteFilters,
    getAppwritePagination,
    getAppwriteSorting,
    Permission,
    Role,
    ID,
} from "@pankod/refine-appwrite";
import { DataProvider } from "@pankod/refine-core";

export const dataProvider = (
    appwriteClient: Appwrite
): Required<DataProvider> => {
    const database = new Databases(appwriteClient);

    return {
        getList: async ({
            metaData,
            resource,
            hasPagination = true,
            pagination = { current: 1, pageSize: 10 },
            filters,
            sort,
        }) => {
            const databaseId = metaData?.databaseId ?? "default";
            const appwriteFilters = getAppwriteFilters(filters);
            const appwritePagination = hasPagination
                ? getAppwritePagination(pagination)
                : [];

            const appwriteSorts = getAppwriteSorting(sort);

            const { total, documents: data } =
                await database.listDocuments<any>(databaseId, resource, [
                    ...appwriteFilters,
                    ...appwritePagination,
                    ...appwriteSorts,
                ]);

            return {
                data: data.map(({ $id, ...restData }: { $id: string }) => ({
                    id: $id,
                    ...restData,
                })) as any,
                total,
            };
        },
        getOne: async ({ metaData, resource, id }) => {
            const databaseId = metaData?.databaseId ?? "default";
            const { $id, ...restData } = await database.getDocument(
                databaseId,
                resource,
                id.toString()
            );

            return {
                data: {
                    id: $id,
                    ...restData,
                },
            } as any;
        },
        update: async ({ metaData, resource, id, variables }) => {
            const databaseId = metaData?.databaseId ?? "default";
            const permissions = [
                Permission.read(Role.any()),
                Permission.write(Role.any()),
                ...(metaData?.readPermissions ?? ""),
                ...(metaData?.writePermissions ?? ""),
            ];
            const { $id, ...restData } = await database.updateDocument(
                databaseId,
                resource,
                id.toString(),
                variables as any,
                permissions
            );

            return {
                data: {
                    id: $id,
                    ...restData,
                },
            } as any;
        },
        create: async ({ metaData, resource, variables }) => {
            const databaseId = metaData?.databaseId ?? "default";

            const { $id, ...restData } = await database.createDocument(
                databaseId,
                resource,
                metaData?.documentId ?? ID.unique(),
                variables as unknown as object,
                metaData?.permissions
            );

            return {
                data: {
                    id: $id,
                    ...restData,
                },
            } as any;
        },
        createMany: async ({ metaData, resource, variables }) => {
            const databaseId = metaData?.databaseId ?? "default";
            const data = await Promise.all(
                variables.map((document) =>
                    database.createDocument<any>(
                        databaseId,
                        resource,
                        metaData?.documentId ?? ID.unique(),
                        document as unknown as any,
                        metaData?.permissions
                    )
                )
            );

            return {
                data: data.map(({ $id, ...restData }) => ({
                    id: $id,
                    ...restData,
                })),
            } as any;
        },
        deleteOne: async ({ metaData, resource, id }) => {
            const databaseId = metaData?.databaseId ?? "default";
            await database.deleteDocument(databaseId, resource, id.toString());

            return {
                data: { id },
            } as any;
        },
        deleteMany: async ({ metaData, resource, ids }) => {
            const databaseId = metaData?.databaseId ?? "default";
            await Promise.all(
                ids.map((id) =>
                    database.deleteDocument(databaseId, resource, id.toString())
                )
            );

            return {
                data: ids.map((id) => ({
                    id,
                })),
            } as any;
        },
        getMany: async ({ metaData, resource, ids }) => {
            const databaseId = metaData?.databaseId ?? "default";
            const data = await Promise.all(
                ids.map((id) =>
                    database.getDocument<any>(
                        databaseId,
                        resource,
                        id.toString()
                    )
                )
            );

            return {
                data: data.map(({ $id, ...restData }) => ({
                    id: $id,
                    ...restData,
                })),
            } as any;
        },
        updateMany: async ({ metaData, resource, ids, variables }) => {
            const databaseId = metaData?.databaseId ?? "default";
            const permissions = [
                Permission.read(Role.any()),
                Permission.write(Role.any()),
                ...(metaData?.readPermissions ?? ""),
                ...(metaData?.writePermissions ?? ""),
            ];
            const data = await Promise.all(
                ids.map((id) =>
                    database.updateDocument<any>(
                        databaseId,
                        resource,
                        id.toString(),
                        variables as unknown as object,
                        permissions
                    )
                )
            );

            return {
                data: data.map(({ $id, ...restData }) => ({
                    id: $id,
                    ...restData,
                })),
            } as any;
        },
        getApiUrl: () => {
            throw Error(
                "'getApiUrl' method is not implemented on refine-appwrite data provider."
            );
        },
        custom: () => {
            throw Error(
                "'custom' method is not implemented on refine-appwrite data provider."
            );
        },
    };
};
