import { Account, Appwrite, Storage, Teams } from "@pankod/refine-appwrite";

const appwriteClient = new Appwrite();

appwriteClient
    .setEndpoint(process.env.REACT_APP_APPWRITE_ENDPOINT || "")
    .setProject(process.env.REACT_APP_APPWRITE_PROJECT_ID || "");
const account = new Account(appwriteClient);
const storage = new Storage(appwriteClient);
const teams = new Teams(appwriteClient);

export { appwriteClient, account, storage, teams };
