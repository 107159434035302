export const Constants = {
    databases: {
        general: "general",
    },
    collections: {
        organizations: "organizations",
        organizationDetails: "organization-details",
        lists: "lists",
        tasks: "tasks",
    },
};
