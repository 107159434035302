import {
    RefineSnackbarProvider,
    CssBaseline,
    GlobalStyles,
    ThemeProvider,
    LightTheme,
} from "@pankod/refine-mui";
import { OrganizationProvider } from "context/organization";
import { PropsWithChildren } from "react";

export const AppProviders: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <ThemeProvider theme={LightTheme}>
            <CssBaseline />
            <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
            <RefineSnackbarProvider>
                <OrganizationProvider>{children}</OrganizationProvider>
            </RefineSnackbarProvider>
        </ThemeProvider>
    );
};
