import React, { createContext, useState } from "react";

export interface IOrganizationContext {
    organization: string;
    setOrganization: (id: string) => void;
}

export const OrganizationContext = createContext<IOrganizationContext | null>(
    null
);

export const OrganizationProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const [organization, setOrganization] = useState<string>("");

    return (
        <OrganizationContext.Provider
            value={{
                organization: organization,
                setOrganization: setOrganization,
            }}
        >
            {children}
        </OrganizationContext.Provider>
    );
};
