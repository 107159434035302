import {
    Appwrite,
    getAppwriteFilters,
    getAppwritePagination,
    getAppwriteSorting,
    Functions,
} from "@pankod/refine-appwrite";
import { DataProvider } from "@pankod/refine-core";

export const functionExecutionsProvider = (
    appwriteClient: Appwrite
): Required<DataProvider> => {
    const functions = new Functions(appwriteClient);

    return {
        getList: async ({
            metaData,
            resource,
            hasPagination = true,
            pagination = { current: 1, pageSize: 10 },
            filters,
            sort,
        }) => {
            const search = metaData?.search;
            const appwriteFilters = getAppwriteFilters(filters);
            const appwritePagination = hasPagination
                ? getAppwritePagination(pagination)
                : [];

            const appwriteSorts = getAppwriteSorting(sort);

            const { total, executions: data } = await functions.listExecutions(
                resource,
                [...appwriteFilters, ...appwritePagination, ...appwriteSorts],
                search
            );

            return {
                data: data.map(({ $id, ...restData }: { $id: string }) => ({
                    id: $id,
                    ...restData,
                })) as any,
                total,
            };
        },
        getOne: async ({ metaData, resource, id }) => {
            const { $id, ...restData } = await functions.getExecution(
                resource,
                id.toString()
            );

            return {
                data: {
                    id: $id,
                    ...restData,
                },
            } as any;
        },
        update: async ({ metaData, resource, id, variables }) => {
            throw Error("Not implemented.");
        },
        create: async ({ metaData, resource, variables }) => {
            const { $id, ...restData } = await functions.createExecution(
                resource,
                JSON.stringify(variables as unknown as object),
                metaData?.async ?? false
            );

            return {
                data: {
                    id: $id,
                    ...restData,
                },
            } as any;
        },
        createMany: async ({ metaData, resource, variables }) => {
            throw Error("Not implemented.");
        },
        deleteOne: async ({ metaData, resource, id }) => {
            throw Error("Not implemented.");
        },
        deleteMany: async ({ metaData, resource, ids }) => {
            throw Error("Not implemented.");
        },
        getMany: async ({ metaData, resource, ids }) => {
            throw Error("Not implemented.");
        },
        updateMany: async ({ metaData, resource, ids, variables }) => {
            throw Error("Not implemented.");
        },
        getApiUrl: () => {
            throw Error(
                "'getApiUrl' method is not implemented on refine-appwrite data provider."
            );
        },
        custom: () => {
            throw Error(
                "'custom' method is not implemented on refine-appwrite data provider."
            );
        },
    };
};
