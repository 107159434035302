import { TextField } from "@pankod/refine-mui";
import { UseModalFormReturnType } from "@pankod/refine-react-hook-form";

import { Modal } from "../modal";

export const Create: React.FC<UseModalFormReturnType> = ({
    register,
    refineCore: { formLoading },
    modal: { visible, close },
    saveButtonProps,
}) => {
    return (
        <Modal
            isOpen={visible}
            onClose={close}
            title="Create a Task"
            submitText={formLoading ? "Loading" : "Create"}
            onSubmitClick={saveButtonProps.onClick}
            submitDisabled={saveButtonProps.disabled}
        >
            <TextField
                {...register("listId")}
                type="hidden"
                variant="standard"
            />
            <TextField
                label="Content"
                placeholder="Content"
                margin="normal"
                fullWidth
                {...register("content", {
                    required: "This field is required",
                })}
            />
        </Modal>
    );
};
