import React, { useContext, useEffect } from "react";
import { useDataGrid, DataGrid, GridColumns, List } from "@pankod/refine-mui";
import { useModalForm } from "@pankod/refine-react-hook-form";

import { IMembership, IOrganizationDetail } from "interfaces";
import { Constants } from "utility";
import {
    OrganizationContext,
    IOrganizationContext,
} from "context/organization";
import { useOne, useRouterContext } from "@pankod/refine-core";
import { Invite } from "components/invite";

export const MembershipList: React.FC = () => {
    const { organization, setOrganization } = useContext(
        OrganizationContext
    ) as IOrganizationContext;

    const { id } = useRouterContext().useParams<{ id: string }>();

    useEffect(() => {
        if (organization !== id) {
            setOrganization(id);
        }
    });

    // Setup invite modal
    const inviteModalFormReturnValues = useModalForm({
        refineCoreProps: {
            action: "create",
            redirect: false,
            resource: "memberships",
            dataProviderName: "teams",
            metaData: {
                teamId: organization,
                url: `${window.location.origin}/accept-invite`,
            },
        },
    });

    const {
        modal: { show: showInviteModal },
    } = inviteModalFormReturnValues;

    // Fetch Organiation Details
    const { data: organizationDetailsData } = useOne<IOrganizationDetail>({
        metaData: { databaseId: Constants.databases.general },
        resource: Constants.collections.organizationDetails,
        id: organization,
        queryOptions: {
            enabled: !!organization,
        },
    });

    const organizationName = organizationDetailsData?.data.name ?? "";

    // Fetch Lists
    const { dataGridProps } = useDataGrid<IMembership>({
        dataProviderName: "teams",
        resource: "memberships",
        metaData: {
            teamId: organization,
        },
        queryOptions: {
            enabled: !!organization,
        },
    });

    // Setup table columns
    const columns: GridColumns<IMembership> = [
        {
            field: "userName",
            headerName: "Name",
            minWidth: 50,
            flex: 1,
            renderCell: function render(params) {
                return params.row.userName;
            },
        },
        {
            field: "userEmail",
            headerName: "Email",
            minWidth: 100,
            flex: 1,
            renderCell: function render(params) {
                return params.row.userEmail;
            },
        },
        {
            field: "invited",
            headerName: "Invited",
            minWidth: 50,
            flex: 1,
            renderCell: function render(params) {
                if (!params.row.invited) {
                    return "";
                }

                const d = new Date(params.row.invited);
                return d.toLocaleString();
            },
        },
        {
            field: "joined",
            headerName: "Joined",
            minWidth: 50,
            flex: 1,
            renderCell: function render(params) {
                if (!params.row.joined) {
                    return "";
                }

                const d = new Date(params.row.joined);
                return d.toLocaleString();
            },
        },
        {
            field: "roles",
            headerName: "Roles",
            minWidth: 100,
            flex: 1,
            renderCell: function render(params) {
                return params.row.roles.join(", ");
            },
        },
    ];

    return (
        <>
            <Invite {...inviteModalFormReturnValues} />
            <List
                title={`${organizationName} Members`}
                canCreate={true}
                createButtonProps={{
                    onClick: () => showInviteModal(),
                    children: "Invite",
                }}
            >
                <DataGrid {...dataGridProps} columns={columns} autoHeight />
            </List>
        </>
    );
};
